import { inject }                       from 'aurelia-framework';
import { AggregatesLabTestsRepository } from 'modules/aggregates/lab/services/repository';
import { TpcfFractions }                from 'modules/bituminous-mixtures/models/tpcf-fractions';

@inject(AggregatesLabTestsRepository)
export class ManufacturingPlantTranspositionCompositionFormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param aggregatesLabTestsRepository
     */
    constructor(aggregatesLabTestsRepository) {
        this.aggregatesLabTestsRepository = aggregatesLabTestsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {TpcfFractions}
     */
    model() {
        let model = new TpcfFractions();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.aggregate_lab_test_id = {
            type:         'select2',
            key:          'aggregate_lab_test_id',
            label:        'form.field.sample-number',
            remoteSource: () => this.aggregatesLabTestsRepository.getTTMCbyCurrentLotIntervention(),
            size:         12,
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                (newValue, oldValue) => {
                    if (newValue) {
                        viewModel.model.fraction = this.aggregate_lab_test_id.instance.selectedRecord().fraction;
                    } else {
                        viewModel.model.fraction = null;
                    }
                },
            ],
        };

        this.fraction = {
            type:       'text',
            key:        'fraction',
            label:      'form.field.fraction-d-D',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.percentage = {
            type:       'number',
            key:        'percentage',
            label:      'form.field.percentage',
            size:       12,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        }

        return [
            [this.aggregate_lab_test_id],
            [this.fraction],
            [this.percentage],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }
}
