import { DialogController }                                     from 'aurelia-dialog';
import { bindable, inject }                                     from 'aurelia-framework';
import { PLATFORM }                                             from 'aurelia-pal';
import { BaseFormViewModel }                                    from 'base-form-view-model';
import { ManufacturingPlantTranspositionCompositionFormSchema } from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/fractions/form-schema';
import { ManufacturingPlantTranspositionCompositionRepository } from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/fractions/services/repository';
import { CompositionDataRepository }                            from 'modules/management/bituminous-mixtures/composition-data/services/repository';
import { AppContainer }                                         from 'resources/services/app-container';

@inject(AppContainer, DialogController, ManufacturingPlantTranspositionCompositionRepository, ManufacturingPlantTranspositionCompositionFormSchema, CompositionDataRepository)
export class ViewManufacturingPlantTranspositionFractionModal extends BaseFormViewModel {

    headerTitle = 'form.title.view-record';
    formId      = 'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.view-form';
    readonly    = true;

    @bindable alert = {};
    model           = {};
    schema          = {};

    @bindable settings = {
        title:   'form.title.view-record',
        size:    'modal-md',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     * @param formSchema
     * @param compositionDataRepository
     */
    constructor(appContainer, dialogController, repository, formSchema, compositionDataRepository) {
        super(appContainer);

        this.dialogController          = dialogController;
        this.repository                = repository;
        this.formSchema                = formSchema;
        this.compositionDataRepository = compositionDataRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/formulation-studies/composition-data/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.manage',
            'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        this.schema       = this.formSchema.schema(this, true);
        this.globalSchema = this.formSchema.globalSchema(this, true);
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData({ id }) {
        const response = await this.repository.find(id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
