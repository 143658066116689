import { BaseModel } from 'modules/core/models/base-model';

export class FormulationStudy extends BaseModel {
    id                  = null;
    lot_intervention_id = null;
    team_id             = null;
    study_type_id       = null;
    material_type_id    = null;
    layer_id            = null;
    application_id      = null;
    number              = null;
    approval_date       = null;
    date                = null;
    observations        = null;
}
