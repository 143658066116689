import { DialogService }                                        from 'aurelia-dialog';
import { bindable, inject }                                     from 'aurelia-framework';
import { I18N }                                                 from 'aurelia-i18n';
import { BaseListViewModel }                                    from 'base-list-view-model';
import { CreateManufacturingPlantTranspositionFractionModal }   from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/fractions/create';
import { EditManufacturingPlantTranspositionFractionModal }     from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/fractions/edit';
import { ManufacturingPlantTranspositionCompositionRepository } from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/fractions/services/repository';
import { ViewManufacturingPlantTranspositionFractionModal }     from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/fractions/view';
import { AppContainer }                                         from 'resources/services/app-container';

@inject(AppContainer, ManufacturingPlantTranspositionCompositionRepository, I18N, DialogService)
export class ListManufacturingPlantTranspositionFractions extends BaseListViewModel {

    listingId = 'formulation-studies-composition-data-listing';

    @bindable headerTitle = null;
    @bindable repository;
    @bindable datatable;
    @bindable bmManufacturingPlantTransposition;

    readonly        = false;
    lines           = [];
    calculatedTotal = 0;
    fetchedTotal    = 0;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param dialogService
     */
    constructor(appContainer, repository, i18N, dialogService) {
        super(appContainer);

        this.i18N          = i18N;
        this.repository    = repository;
        this.dialogService = dialogService;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.manage',
            'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.bmManufacturingPlantTransposition = params.model;
        this.readonly                          = params.readonly;

        this.defineDatatable();
        this.schema = this.datatable;

        // TODO: get data from server
        if (params.linesData) {
            this.lines = params.linesData;
        } else {
            const numberOfLines = 1;
            this.lines          = Array.from({ length: numberOfLines }, () => ({ filer: '', percentage: 0 }));
        }

        this.devInfo = this.infoAlertMessage('EM DESENVOLVIMENTO: Algumas funcionalidades ainda não estão implementadas.');

        this.fetchTotal();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search:          (criteria) => this.search(criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:               {
                action:  (row) => this.openModal(row, ViewManufacturingPlantTranspositionFractionModal),
                visible: (row) => this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.manage', 'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.view']),
            },
            edit:               {
                action:  (row) => this.openModal(row, EditManufacturingPlantTranspositionFractionModal),
                visible: (row) => !this.readonly && this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.manage', 'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.edit']),
            },
            destroy:            !this.readonly && this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.manage', 'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.delete']),
            destroySelected:    !this.readonly,
            actionsContextMenu: true,
            showPagination:     false,
            destroyed:          () => this.fetchTotal(),
            destroyedSelected:  () => this.fetchTotal(),
            actions:            [],
            options:            [],
            buttons:            [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly && this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.manage', 'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.fractions.create']),
                    action:           () => this.openModal(this, CreateManufacturingPlantTranspositionFractionModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:         true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:       'aggregate_lab_tests_name',
                    name:       'aggregate_lab_tests_name',
                    title:      'form.field.sample-number',
                    searchable: false,
                },
                {
                    data:       'fraction',
                    name:       'fraction',
                    title:      'form.field.fraction-d-D',
                    searchable: false,
                },
                {
                    data:  'percentage',
                    name:  'tpcf_fractions.percentage',
                    title: 'form.field.percentage',
                    unit:  '%',
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search({ ...criteria, bm_manufacturing_plant_transposition_id: this.bmManufacturingPlantTransposition.id });
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                    this.fetchTotal();
                }
            });
    }

    /**
     * Saves the lines
     */
    saveLines() {
        // TODO: save on server
        console.log(this.bmManufacturingPlantTransposition.id, this.lines);
    }

    /**
     * Updates the total percentage
     */
    updateTotal() {
        this.calculatedTotal = this.fetchedTotal + this.lines.reduce((sum, line) => sum + parseFloat(line.percentage || 0), 0);
    }

    /**
     * Calculates the total percentage
     */
    fetchTotal() {
        return this.repository.calculateTotal(this.bmManufacturingPlantTransposition.id)
            .then((response) => {
                this.fetchedTotal = response;
                this.updateTotal();
            });
    }
}
