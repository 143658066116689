import { BaseModel } from 'modules/core/models/base-model';

export class ManufacturingPlantTransposition extends BaseModel {
    id                      = null;
    lot_intervention_id     = null;
    team_id                 = null;
    bm_formulation_study_id = null;
    material_type_id        = null;
    layer_id                = null;
    number                  = null;
    date                    = null;
    observations            = null;
}
