import { inject }                       from 'aurelia-framework';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseFormViewModel }            from 'base-form-view-model';
import { FormSchema }                   from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/form-schema';
import { ManufacturingPlantTranspositionsRepository } from 'modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/services/repository';
import { AppContainer }                 from 'resources/services/app-container';

@inject(AppContainer, ManufacturingPlantTranspositionsRepository, FormSchema)
export class EditManufacturingPlantTransposition extends BaseFormViewModel {

    headerTitle = 'form.title.edit-record';
    formId      = 'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.edition-form';

    alert          = {};
    model          = {};
    schema         = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('bituminous-mixtures.mixtures.manufacturing-plant-transpositions.index') && super.can([
            'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.manage',
            'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.edit',
        ]);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/form.html');
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        this.schema                      = this.formSchema.schema(this);
        this.additionalInformationSchema = this.formSchema.additionalInformationSchema(this);
        this.mixtureSchema               = this.formSchema.mixtureSchema(this);
        this.lotInfoSchema               = this.formSchema.lotInfoSchema(this);
        this.globalSchema                = this.formSchema.globalSchema(this);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, 'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
