import { BaseAggregatesTest } from 'modules/aggregates/models/base-aggregates-test';

export class BMLigand extends BaseAggregatesTest {
    id                        = null;
    ligand_type               = null;
    central_number            = null;
    central_designation       = null;
    supplier                  = null;
    ligand_application_id     = null;
    validated_by              = null;
    validated_at              = null;
    verified_inspection_by    = null;
    verified_inspection_at    = null;
    verified_concessionary_by = null;
    verified_concessionary_at = null;
}
